<template>
  <div class="container">
    <div class="d-flex justify-content-between m-2 p-3">
      <h2>Variabili:</h2>
      <b-button v-b-modal.editModal variant="dark" @click="setVar(cleanVar)"><b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi variabile</b-button>
    </div>
    <div class="canvas-container" v-for="graph in ifoptions" :key="graph._id">
      <b-button squared class="clickbtn" v-b-toggle="'collapse-' + graph._id" variant="outline-dark">{{ graph.name }}</b-button>
      <b-collapse :id="'collapse-' + graph._id" class="collapse mt-2">
        <b-card>
          <canvas :v-if="!isLoading" class="graph" :ref="`element-${graph._id}`"></canvas>
          <div class="d-flex justify-content-between graph-name">
            <div>{{ graph.name }}</div>
            <div class="mx-4">{{ graph.unit }}</div>
          </div>
          <hr class="mx-4" />
          <div class="graph-description">{{ graph.description }}</div>
          <div class="graph-category"><strong>Categoria: </strong> {{ graph.category }}</div>
          <div class="graph-trigger d-flex align-items-center justify-content-start">
            <strong>Triggerabile: </strong>
            <b-icon-check class="font-trigger" v-if="graph.triggerable"></b-icon-check>
            <b-icon-x class="font-trigger" v-else></b-icon-x>
          </div>
          <div class="graph-btn">
            <b-button v-b-modal.editModal variant="dark" @click="setVar(graph)"><b-icon-pen class="font-trigger"></b-icon-pen> Modifica</b-button>
            <b-button v-b-modal.delModal class="mx-2" variant="danger" @click="setVar(graph)"><b-icon-trash class="font-trigger"></b-icon-trash> Elimina</b-button>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <b-modal id="addX" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Aggiungi valore asse X: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="6">
            <label for="xval">Valore asse X:</label>
          </b-col>
          <b-col sm="6">
            <b-form-input type="number" id="xval" size="sm" v-model.number="addXObj.x" placeholder="Inserisci un valore"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-5" v-for="(qual, i) in selectedVar.qualifiers" :key="qual.name">
          <b-col sm="6">
            <label for="qualin"
              >Qualificatore <b>{{ qual.name }}</b
              >:</label
            >
          </b-col>
          <b-col sm="6">
            <input type="hidden" :value="addXObj.qualx[i] == undefined ? (addXObj.qualx[i] = 0) : 0" />
            <v-slider
              :tooltip="'always'"
              :process-style="{ backgroundColor: 'black', borderColor: 'black' }"
              :dot-style="{ boxShadow: '0px 0px 1px 2px rgba(0,0,0,36)', borderColor: 'black' }"
              :tooltip-style="{ backgroundColor: 'black', borderColor: 'black' }"
              v-model.number="addXObj.qualx[i]"
              :interval="0.01"
              :min="0"
              :max="1"
            ></v-slider>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="dark"
          :disabled="!addXObj.x"
          @click="
            addXVal(addXObj);
            ok();
          "
        >
          <b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
    <b-modal id="addRule" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Aggiungi regola: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <h5 class="mt-4">IF</h5>
        <hr />
        <b-row class="my-1" v-for="(ifv, i) in ifs" :key="ifv.name + ' ' + ifv.value">
          <b-col sm="4">
            <label for="ifSecIndex">Condizione:</label>
          </b-col>
          <b-col sm="4">
            <v-select id="ifIndex" :clearable="false" class="style-chooser" placeholder="Scegli una condizione" :options="ruleOptions(selectedVar.name)" v-model="ifv.name"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
          <b-col sm="4">
            <v-select id="ifIndexVal" :clearable="false" class="style-chooser" placeholder="Scegli uno stato" :options="ruleOptionsValue(ifv.name)" v-if="ifv.name" v-model="ifv.value"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
          <b-col sm="8">
            <b-button v-if="ifv.name && ifv.value" :ref="'a' + i" size="sm" class="m-2" variant="dark" @click="addOption()"> <b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi </b-button>
            <b-button v-if="ifv.name && ifv.value" :ref="'r' + i" size="sm" class="m-2" variant="danger" @click="remOption()"> <b-icon-trash class="font-trigger"></b-icon-trash> Elimina </b-button>
          </b-col>
        </b-row>

        <h5 class="mt-4">THEN</h5>
        <hr />
        <b-row class="my-1">
          <b-col sm="4">
            <label for="descrizione">Indice di sicurezza:</label>
          </b-col>
          <b-col sm="4">
            <v-select id="ThenSecIndex" :clearable="false" class="style-chooser" placeholder="Scegli un indice" :options="secIndex" v-model="then.name"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
          <b-col sm="4"
            ><v-select id="ThenSecIndexVal" :clearable="false" class="style-chooser" placeholder="Scegli un livello" :options="secIndexVal" v-model="then.value"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="dark"
          :disabled="!then.value || !then.name || (ifs.length == 1 && ifs[0].value == null) || ifs[ifs.length - 1].value == null"
          @click="
            addRegola({ if: ifs, then: then });
            ok();
          "
        >
          <b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
    <b-modal id="addSens" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Aggiungi sensore: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="4">
            <label for="ifSecIndex">Nome sensore:</label>
          </b-col>
          <b-col sm="4">
            <v-select id="ifIndex" :clearable="false" class="style-chooser" placeholder="Scegli un sensore" :options="sensOptions()" v-model="sensObj.name"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
        </b-row>

        <b-table class="my-2" hover :items="sensObj.values" :fields="fields">
          <template #cell(then)="data">
            <div class="d-flex align-items-center justify-content-around">
              <div class="d-flex flex-sm-wrap"><b>Safety</b>: <b-form-select :options="secIndexVal" v-model="sensObj.values[data.index].then.Safety" size="sm"></b-form-select></div>
              <div class="d-flex flex-sm-wrap"><b>Security</b>: <b-form-select :options="secIndexVal" v-model="sensObj.values[data.index].then.Security" size="sm"></b-form-select></div>
              <div class="d-flex flex-sm-wrap"><b>Privacy</b>: <b-form-select :options="secIndexVal" v-model="sensObj.values[data.index].then.Privacy" size="sm"></b-form-select></div>
            </div>
          </template>
          <template #cell(value)="data">
            <b-form-input size="sm" type="number" v-model.number="sensObj.values[data.index].value"></b-form-input>
          </template>
        </b-table>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          :disabled="
            !sensObj.name ||
              !sensObj.values[0].value ||
              !sensObj.values[0].then.Safety ||
              !sensObj.values[0].then.Privacy ||
              !sensObj.values[0].then.Security ||
              !sensObj.values[1].value ||
              !sensObj.values[1].then.Safety ||
              !sensObj.values[1].then.Privacy ||
              !sensObj.values[1].then.Security ||
              !sensObj.values[2].value ||
              !sensObj.values[2].then.Safety ||
              !sensObj.values[2].then.Privacy ||
              !sensObj.values[2].then.Security
          "
          variant="dark"
          @click="
            addSensor(sensObj);
            ok();
          "
        >
          <b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
    <b-modal id="delX" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Elimina valore asse X: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="6">
            <label for="xvalr">Valore asse X:</label>
          </b-col>
          <b-col sm="6">
            <v-select id="xvalr" class="style-chooser" placeholder="Scegli un valore" :options="selectedVar.support" :value="selectedVar.support[0]" v-model="toRemX"
              ><span slot="no-options">Nessuna opzione</span></v-select
            >
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          :disabled="!toRemX"
          variant="danger"
          @click="
            delXValue(toRemX);
            ok();
          "
        >
          <b-icon-trash class="font-trigger"></b-icon-trash> Elimina
        </b-button>
        <b-button size="sm" variant="dark" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
    <b-modal id="delQual" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Elimina qualificatore {{ selectedDelQual }}: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="12">
            Sei sicuro di voler eliminare il qualificatore <b>{{ selectedDelQual }}</b
            >?
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="dark" @click="cancel()">
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="
            delQualificatore(selectedDelQual);
            ok();
          "
        >
          <b-icon-trash class="font-trigger"></b-icon-trash> Elimina
        </b-button>
      </template></b-modal
    >
    <b-modal id="addQual" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Aggiungi qualificatore: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="6">
            <label for="name">Nome qualificatore:</label>
          </b-col>
          <b-col sm="6">
            <b-form-input id="name" size="sm" placeholder="Inserisci un nome" v-model="addQualObj.name"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="my-3" v-for="(xval, i) in selectedVar.support" :key="xval">
          <b-col sm="6">
            <label for="xval"
              >Valore <b>{{ xval }}</b
              >:</label
            >
          </b-col>
          <b-col sm="6">
            <input type="hidden" :value="addQualObj.values[i] == undefined ? (addQualObj.values[i] = 0) : 0" />
            <v-slider
              :process-style="{ backgroundColor: 'black', borderColor: 'black' }"
              :dot-style="{ boxShadow: '0px 0px 1px 2px rgba(0,0,0,36)', borderColor: 'black' }"
              :tooltip-style="{ backgroundColor: 'black', borderColor: 'black' }"
              v-model.number="addQualObj.values[i]"
              :interval="0.01"
              :min="0"
              :max="1"
            ></v-slider>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="dark"
          :disabled="!addQualObj.name"
          @click="
            addQualificatore(addQualObj);
            ok();
          "
        >
          <b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
    <b-modal id="delRule" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Elimina regola #{{ selectedDelRule }}: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="12">
            Sei sicuro di voler eliminare la <b>regola #{{ selectedDelRule }}</b
            >?
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="dark" @click="cancel()">
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="
            delRegola(selectedDelRule);
            ok();
          "
        >
          <b-icon-trash class="font-trigger"></b-icon-trash> Elimina
        </b-button>
      </template></b-modal
    >
    <b-modal id="delSens" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Elimina sensore: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="12"> Sei sicuro di voler eliminare il <b>sensore</b>? </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="dark" @click="cancel()">
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="
            delSensore();
            ok();
          "
        >
          <b-icon-trash class="font-trigger"></b-icon-trash> Elimina
        </b-button>
      </template></b-modal
    >
    <b-modal id="delModal" size="lg">
      <template #modal-header="{ close }">
        <h4 class="mt-2">
          Elimina variabile: <b>{{ selectedVar.name }}</b>
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-row class="my-1">
          <b-col sm="12">
            Sei sicuro di voler eliminare la variabile <b>{{ selectedVar.name }}</b
            >?
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="dark" @click="cancel()">
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="
            deleteVar(selectedVar);
            ok();
          "
        >
          <b-icon-trash class="font-trigger"></b-icon-trash> Elimina
        </b-button>
      </template></b-modal
    >
    <b-modal id="editModal" size="xl">
      <template #modal-header="{ close }">
        <h4 class="mt-2" v-if="selectedVar.name">
          Modifica variabile: <b>{{ selectedVar.name }}</b>
        </h4>
        <h4 class="mt-2" v-else>
          Inserimento variabile
        </h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
      <template #default>
        <b-container fluid>
          <h5 class="mt-4">Dettagli</h5>
          <hr />
          <b-row class="my-1" v-if="!selectedVar.name">
            <b-col sm="4">
              <label for="nome">Nome:</label>
            </b-col>
            <b-col sm="6">
              <b-form-input id="nome" size="sm" v-model="tempname" placeholder="Inserisci un nome"></b-form-input>
            </b-col>
            <b-col sm="2">
              <b-button size="sm" variant="info" @click="selectedVar.name = tempname"> <b-icon-check class="font-trigger"></b-icon-check> Imposta </b-button>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4">
              <label for="descrizione">Descrizione:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="descrizione" v-model="selectedVar.description" size="sm" placeholder="Inserisci una descrizione"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4">
              <label for="categoria">Categoria:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="categoria" v-model="selectedVar.category" size="sm" placeholder="Inserisci una categoria"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4">
              <label for="triggerabile">Triggerabile:</label>
            </b-col>
            <b-col sm="8">
              <b-form-radio-group id="triggerabile" v-model="selectedVar.triggerable" :options="triggerOptions" />
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="4">
              <label for="unit">Unità di misura:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="unit" v-model="selectedVar.unit" size="sm" placeholder="Inserisci una unità di misura"></b-form-input>
            </b-col>
          </b-row>

          <b-row class="my-4" v-if="!selectedVar.name">
            <b-col sm="12" class="text-center">Prima di procedere, <strong>impostare un nome!</strong></b-col>
          </b-row>
          <div v-if="selectedVar.name">
            <h5 class="mt-4">Grafico</h5>
            <hr />

            <b-row class="my-1">
              <b-col sm="4">
                <label for="unit">Asse X:</label>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="4" md="3" lg="2" xl="1" v-for="(sup, index) in selectedVar.support" :key="index">
                <b-form-input type="number" step="0.01" class="col-sm-1 my-1" v-model.number="selectedVar.support[index]" size="sm"></b-form-input>
              </b-col>
              <b-col sm="8" md="6" lg="4" xl="2"
                ><b-button variant="dark" v-b-modal.addX size="sm" class="my-1"><b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi valore</b-button></b-col
              ><b-col sm="8" md="6" lg="4" xl="2"
                ><b-button variant="danger" v-if="selectedVar.support.length !== 0" v-b-modal.delX size="sm" class="my-1"
                  ><b-icon-trash class="font-trigger"></b-icon-trash> Elimina valore</b-button
                ></b-col
              >
            </b-row>

            <hr v-if="selectedVar.support.length !== 0" />

            <b-row class="my-1" v-for="qual in selectedVar.qualifiers" :key="qual.name">
              <b-col sm="12">
                <label for="unit" class="my-2"
                  >Qualificatore <b>{{ qual.name }}</b
                  >:</label
                >
              </b-col>

              <b-col sm="4" md="3" lg="2" xl="1" v-for="(val, index) in qual.values" :key="index">
                <b-form-input type="number" step="0.01" class="my-1" v-model.number="qual.values[index]" size="sm"></b-form-input>
              </b-col>
              <b-col sm="8" md="6" lg="4" xl="2"
                ><b-button size="sm" v-b-modal.delQual @click="setDelQual(qual.name)" variant="danger" class="my-1"
                  ><b-icon-trash class="font-trigger"></b-icon-trash> Elimina {{ qual.name }}
                </b-button></b-col
              >
            </b-row>
            <b-row class="my-2" v-if="selectedVar.qualifiers.length !== 0">
              <b-col> <b>Attenzione!</b> Eliminando qualificatori verranno invalidate alcune regole che li utilizzano! Assicurarsi che questo non accada </b-col>
            </b-row>
            <b-button v-if="selectedVar.support.length !== 0" v-b-modal.addQual variant="dark" class="mt-2"><b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi qualificatore</b-button>

            <h5 class="mt-4">Regole in <span v-b-tooltip.hover.bottom="wiki">notazione polacca inversa (RPN)</span></h5>
            <hr />

            <b-row v-if="selectedVar.rules.length === 0">
              <b-col sm="12">Non esistono regole impostate</b-col>
            </b-row>

            <b-row v-else class="my-4" v-for="(rule, index) in selectedVar.rules" :key="rule.then.name + ' ' + rule.then.value">
              <b-col sm="12">
                <div>
                  <b>Regola #{{ index + 1 }}</b>
                </div>
              </b-col>
              <b-col cols="auto">
                <b-form-input readonly class="bg-dark text-white text-center my-1" size="sm" value="IF"></b-form-input>
              </b-col>
              <b-col cols="auto" v-for="ifvalue in rule.if" :key="ifvalue.name + ' ' + ifvalue.value">
                <b-form-input readonly v-if="ifvalue.name === 'Operator'" class="bg-success text-white text-center my-1" size="sm" :value="ifvalue.value"></b-form-input>
                <b-form-input readonly v-else class="bg-warning text-white text-center my-1" size="sm" :value="ifvalue.name + ' ' + ifvalue.value"></b-form-input>
              </b-col>
              <b-col cols="auto">
                <b-form-input readonly class="bg-dark text-white text-center my-1" size="sm" value="THEN"></b-form-input>
              </b-col>
              <b-col cols="auto">
                <b-form-input readonly class="bg-warning text-white text-center my-1" size="sm" :value="rule.then.name + ' ' + rule.then.value"></b-form-input>
              </b-col>
              <b-col sm="12"
                ><b-button size="md" v-b-modal.delRule @click="setDelRule(index + 1)" variant="danger" class="my-2"
                  ><b-icon-trash class="font-trigger"></b-icon-trash> Elimina regola #{{ index + 1 }}
                </b-button></b-col
              >
            </b-row>
            <b-button variant="dark" v-b-modal.addRule class="mt-2"><b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi regola</b-button>

            <h5 class="mt-4">Sensori</h5>
            <hr />

            <b-row v-if="selectedVar.sensor.length === 0">
              <b-col sm="12">Non esistono sensori impostati</b-col>
            </b-row>

            <b-row v-else class="my-4">
              <b-col sm="12">
                Attivo: <b>{{ selectedVar.sensor[0].name }}</b>
              </b-col>
              <b-col sm="12">
                <b-table class="my-2" hover :items="selectedVar.sensor[0].values" :fields="fields">
                  <template #cell(then)="data">
                    <div class="d-flex align-items-center justify-content-between">
                      <div><b>Safety</b>: {{ data.item.then["Safety"] }}</div>
                      <div><b>Security</b>: {{ data.item.then["Security"] }}</div>
                      <div><b>Privacy</b>: {{ data.item.then["Privacy"] }}</div>
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col sm="12"
                ><b-button size="md" v-b-modal.delSens variant="danger" class="my-2"><b-icon-trash class="font-trigger"></b-icon-trash> Elimina sensore </b-button></b-col
              >
            </b-row>
            <b-button v-if="selectedVar.sensor.length === 0" v-b-modal.addSens variant="dark" class="mt-2"><b-icon-plus class="font-trigger"></b-icon-plus> Aggiungi sensore</b-button>
          </div>
          <b-row>
            <b-col class="d-flex" sm="4"><h5 class="mt-4">Valori Grezzi</h5></b-col>
            <b-col class="d-flex justify-content-end" sm="4" offset-sm="4" v-if="jsonToggler"
              ><b-button @click="jsonToggler = !jsonToggler" variant="dark" class="mt-2"><b-icon-eye class="font-trigger"></b-icon-eye> Mostra</b-button></b-col
            >
            <b-col class="d-flex justify-content-end" sm="4" offset-sm="4" v-else
              ><b-button variant="dark" @click="jsonToggler = !jsonToggler" class="mt-2"><b-icon-eye-slash class="font-trigger"></b-icon-eye-slash> Nascondi</b-button></b-col
            >
          </b-row>
          <hr />
          <div>
            <vue-json-pretty :path="'res'" v-show="!jsonToggler" :data="selectedVar"> </vue-json-pretty>
          </div>

          <b-row>
            <b-col class="d-flex" sm="4"><h5 class="mt-4">Grafico</h5></b-col>
            <b-col class="d-flex justify-content-end" sm="4" offset-sm="4" v-if="graphToggler"
              ><b-button
                @click="
                  graphToggler = !graphToggler;
                  showGraph();
                "
                variant="dark"
                class="mt-2"
                ><b-icon-eye class="font-trigger"></b-icon-eye> Mostra</b-button
              ></b-col
            >
            <b-col class="d-flex justify-content-end" sm="4" offset-sm="4" v-else
              ><b-button variant="dark" @click="graphToggler = !graphToggler" class="mt-2"><b-icon-eye-slash class="font-trigger"></b-icon-eye-slash> Nascondi</b-button></b-col
            >
          </b-row>
          <hr />
          <div>
            <canvas class="graph" v-show="!graphToggler" :ref="`edit-${selectedVar._id}`"></canvas>
          </div>
        </b-container>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="dark"
          @click="
            update();
            ok();
          "
        >
          Aggiorna
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Annulla
        </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import FuzzyVarService from "../services/FuzzyVarService";
import Chart from "chart.js";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

//const mongo = require("../mongodb");
export default {
  name: "Graphs",
  data: function() {
    return {
      jsonToggler: true,
      graphToggler: true,
      tempname: null,
      editMode: null,
      config: {},
      isLoading: false,
      fuzzyVars: [],
      ifvalue: "",
      wiki: {
        title:
          'La <b>notazione polacca inversa</b> (in inglese reverse polish notation o semplicemente RPN) è una sintassi utilizzata per le formule matematiche. Leggi su <a href="https://it.wikipedia.org/wiki/Notazione_polacca_inversa" target="_blank">Wikipedia</a>',
        html: true,
      },
      triggerOptions: [
        { text: "Si", value: "true" },
        { text: "No", value: "false" },
      ],
      selectedVar: {},
      selectedDelQual: "",
      selectedDelRule: "",
      toRemX: null,
      addXObj: {
        x: null,
        qualx: [],
      },
      addQualObj: {
        name: null,
        values: [],
      },
      sensObj: {
        name: null,
        values: [
          { name: "Basso", value: null, then: { Safety: null, Security: null, Privacy: null } },
          { name: "Medio", value: null, then: { Safety: null, Security: null, Privacy: null } },
          { name: "Alto", value: null, then: { Safety: null, Security: null, Privacy: null } },
        ],
      },
      then: {},
      secIndex: ["Privacy", "Security", "Safety"],
      secIndexVal: ["Basso", "Alto", "Nulla"],
      currRule: null,
      currRuleOption: null,
      ifs: [{ name: null, value: null }],
      fields: [
        {
          key: "name",
          label: "Nome",
          sortable: true,
        },
        {
          key: "value",
          label: "Valore",
          sortable: true,
        },
        {
          key: "then",
          label: "Indici di sicurezza",
          sortable: true,
        },
      ],
      ifoptions: [
        {
          name: "",
          qualifiers: [],
          support: [],
        },
      ],
      cleanVar: {
        name: null,
        qualifiers: [],
        support: [],
        triggerable: false,
        unit: null,
        sensor: [],
        description: null,
        category: null,
        rules: [],
      },
    };
  },

  created: function() {
    document.title = "Knowledge Base Editor";
  },

  mounted: function() {
    this.$emit("startLoad", true);
    FuzzyVarService.getVars().then((res) => {
      this.$emit("startLoad", false);
      this.isLoading = true;
      this.ifoptions = res.data;
      this.ifvalue = res.data[0].name;
      this.$nextTick(() => {
        this.ifoptions.forEach((e, i) => {
          let dataset = [];
          e.qualifiers.forEach((f) => {
            dataset.push({
              label: f.name,
              data: f.values,
              fill: false,
              borderColor: this.getRandomColor(),
              tension: 0.1,
            });
          });

          this.config = {
            type: "line",
            data: {
              labels: this.ifoptions[i].support,
              datasets: dataset,
            },
          };
          let id = "element-" + e._id;
          new Chart(this.$refs[id], this.config);
          this.$emit("startLoad", false);
          this.isLoading = false;
        });
      });
    });
  },

  computed: {},

  components: {
    VueJsonPretty,
  },

  methods: {
    update() {
      if (this.editMode == "add") {
        FuzzyVarService.addVar(this.cleanVar)
          .then((res) => {
            window.location.reload();
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.editMode == "edit") {
        FuzzyVarService.editVar(this.selectedVar)
          .then((res) => {
            window.location.reload();
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteVar(variable) {
      FuzzyVarService.removeVar(variable)
        .then((res) => {
          window.location.reload();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showGraph() {
      this.ifvalue = this.selectedVar.name;
      let dataset = [];
      this.selectedVar.qualifiers.forEach((f) => {
        dataset.push({
          label: f.name,
          data: f.values,
          fill: false,
          borderColor: this.getRandomColor(),
          tension: 0.1,
        });
      });

      this.config = {
        type: "line",
        data: {
          labels: this.selectedVar.support,
          datasets: dataset,
        },
      };
      let id = "edit-" + this.selectedVar._id;
      new Chart(this.$refs[id], this.config);
    },

    setVar(item) {
      if (item.name == null) {
        this.editMode = "add";
        this.cleanVar._id = this.objectId();
      } else this.editMode = "edit";
      this.selectedVar = item;
    },

    ruleOptions(item) {
      let ruleOptions = [];
      ruleOptions.push(item);
      ruleOptions.push("Operator");
      return ruleOptions;
    },

    sensOptions() {
      return this.ifoptions.filter((el) => el.triggerable == false).map((el) => el.name);
    },

    addOption() {
      this.$refs["a" + (this.ifs.length - 1)][0].style.display = "none";
      this.$refs["r" + (this.ifs.length - 1)][0].style.display = "none";
      this.ifs.push({ name: null, value: null });
    },

    remOption() {
      this.ifs.pop();
      if (this.ifs.length == 0) {
        this.ifs.push({ name: null, value: null });
      }
      this.$refs["a" + (this.ifs.length - 1)][0].style.display = "block";
      this.$refs["r" + (this.ifs.length - 1)][0].style.display = "block";
    },

    ruleOptionsValue(ruleOption) {
      if (ruleOption == "Operator") return ["OR", "AND"];
      else if (this.editMode == "edit")
        for (const val of this.ifoptions) {
          if (val.name == ruleOption) {
            return val.qualifiers.map((el) => el.name);
          }
        }
      else return this.cleanVar.qualifiers.map((el) => el.name);
    },

    setDelQual(item) {
      this.selectedDelQual = item;
    },

    setDelRule(item) {
      this.selectedDelRule = item;
    },

    delXValue(toDelVal) {
      let index = this.selectedVar.support.indexOf(toDelVal);
      this.selectedVar.support.splice(index, 1);
      for (let qual of this.selectedVar.qualifiers) {
        qual.values.splice(index, 1);
      }
    },

    addXVal(addXObj) {
      let index = this.selectedVar.support.indexOf(addXObj.x);
      let i = 0;
      if (index == -1) {
        for (i = 0; i < this.selectedVar.support.length; i++) {
          if (this.selectedVar.support[i] < addXObj.x) i++;
          else {
            i--;
            break;
          }
        }
        console.log(i);
        this.selectedVar.support.splice(i, 0, addXObj.x);
        for (let j = 0; j < this.selectedVar.qualifiers.length; j++) {
          this.selectedVar.qualifiers[j].values.splice(i, 0, addXObj.qualx[j]);
        }
      }
      addXObj.x = null;
      addXObj.qualx = [];
    },

    delQualificatore(selectedDelQual) {
      for (let j = 0; j < this.selectedVar.qualifiers.length; j++) {
        if (this.selectedVar.qualifiers[j].name == selectedDelQual) {
          this.selectedVar.qualifiers.splice(j, 1);

          break;
        }
      }
    },

    addQualificatore(addQualObj) {
      this.selectedVar.qualifiers.push(Object.assign({}, addQualObj));

      addQualObj.name = null;
      addQualObj.values = [];
    },

    addRegola(rule) {
      this.selectedVar.rules.push(rule);
      this.ifs = [{ name: null, value: null }];
      this.then = {};
    },

    delRegola(selectedDelRule) {
      this.selectedVar.rules.splice(selectedDelRule - 1, 1);
    },

    addSensor(sensor) {
      this.selectedVar.sensor.push(sensor);
      this.sensObj = {
        name: null,
        values: [
          { name: "Basso", value: null, then: { Safety: null, Security: null, Privacy: null } },
          { name: "Medio", value: null, then: { Safety: null, Security: null, Privacy: null } },
          { name: "Alto", value: null, then: { Safety: null, Security: null, Privacy: null } },
        ],
      };
    },

    delSensore() {
      this.selectedVar.sensor = [];
    },

    getRandomColor() {
      let colorStr = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
      return colorStr;
    },

    objectId() {
      const os = require("os");
      const crypto = require("crypto");

      const seconds = Math.floor(new Date() / 1000).toString(16);
      const machineId = crypto
        .createHash("md5")
        .update(os.hostname())
        .digest("hex")
        .slice(0, 6);
      const processId = process.pid
        .toString(16)
        .slice(0, 4)
        .padStart(4, "0");
      const counter = (123456789)
        .toString(16)
        .slice(0, 6)
        .padStart(6, "0");

      return seconds + machineId + processId + counter;
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph {
  margin: 0 auto;
}

.graph-name > * {
  background: white;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  font-weight: bold;
  margin: 4px;
  border-radius: 5px;
}

.graph-description {
  background: white;
  padding-left: 1.5rem;
  margin: 4px;
  border-radius: 5px;
}

.graph-category {
  background: white;
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  margin: 4px;
  border-radius: 5px;
}

.graph-trigger {
  background: white;
  padding-left: 1.5rem;
  margin: 4px;
  border-radius: 5px;
}

.graph-btn {
  background: white;
  padding-left: 1.5rem;
  margin: 4px;
  border-radius: 5px;
}

.canvas-container {
  margin: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.clickbtn {
  width: 50%;
  min-width: 18rem;
  margin: 0 auto;
}

.collapse {
  margin: 0 auto;
  width: 70%;
  min-width: 18rem;
}

.container {
  margin-bottom: 8rem;
}

.graph-trigger > .font-trigger {
  font-size: 2rem;
}

.invisible {
  display: none;
}
</style>
